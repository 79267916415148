import React from 'react';
import {
  CompassOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  BlockOutlined,
  SwapOutlined,
  ClusterOutlined,
  PieChartOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'AI Marketplace',
    key: 'overview',
    // TODO use path variable
    url: '/',
    icon: <HomeOutlined />,
  },
  {
    title: 'AI Competition',
    key: 'competition',
    icon: <ClusterOutlined />,
    // url: '/competition',

    children: [
      {
        title: 'Join',
        key: 'join',
        url: '/competition/join',
      },
      {
        title: 'Datasets',
        key: 'datasets',
        url: '/competition/datasets',
      },
      {
        title: 'Leaderboard',
        key: 'leaderboard',
        url: '/competition',
      },
      {
        title: 'Airdrop',
        key: 'airdrop',
        url: '/competition/airdrop',
      },
    ],
  },

  {
    title: 'Account',
    key: 'account',
    icon: <UserOutlined />,
    url: '/account',
  },
];
